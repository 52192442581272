<template>
  <div>
    <PageBar Image="img/article_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <div class="main">
      <div class="container" v-if="Exam && Lesson && User">
        <h1>
          {{ Lesson.Title }}
        </h1>
        <div class="pageBox">
          <div class="qustionPage">
            <div class="testerInfo">
              <p class="name">姓名: {{ User.name || User.Name }}</p>
              <p class="subject">{{ Exam.Title }}</p>
              <p class="testID">准考證號碼: {{ StartTime }}</p>
            </div>
            <div class="remainTime">
              剩餘時間:
              <span class="minute">{{ LeftMin }}</span> 分
              <span class="second">{{ LeftSec }}</span> 秒
            </div>

            <template v-if="Questions">
              <ol class="questionList" v-if="Questions.Single.length > 0">
                <li class="questionList-item" v-for="(q, i) in Questions.Single" :key="q.Title">
                  <h3>
                    {{ q.Title }}
                  </h3>
                  <div class="answers">
                    <label :for="'a' + i + '-' + idx" v-for="(c, idx) in q.Choices" :key="c.Title">
                      <input type="radio" :id="'a' + i + '-' + idx" :name="'a' + i" :value="idx" v-model="q.Answer" />
                      （{{ idx + 1 }}） {{ c.Title }}
                    </label>
                  </div>
                </li>
              </ol>

              <hr v-if="Questions.Single.length > 0 && Questions.Multiple.length > 0" />

              <ol class="questionList" v-if="Questions.Multiple.length > 0">
                <li class="questionList-item" v-for="(q, i) in Questions.Multiple" :key="q.Title">
                  <h3>
                    {{ q.Title }}
                  </h3>
                  <div class="answers">
                    <label :for="'b' + i + '-' + idx" v-for="(c, idx) in q.Choices" :key="c.Title">
                      <input type="checkbox" :id="'b' + i + '-' + idx" :name="'b' + i" :value="idx"
                        v-model="q.Answer" />
                      （{{ idx + 1 }}） {{ c.Title }}
                    </label>
                  </div>
                </li>
              </ol>

              <hr v-if="Questions.Multiple.length > 0 && Questions.Question.length > 0" />

              <ol class="questionList" v-if="Questions.Question.length > 0">
                <li class="questionList-item" v-for="q in Questions.Question" :key="q.Title">
                  <h3>{{ q.Title }}</h3>
                  <div class="answers">
                    <textarea cols="30" rows="10" v-model="q.Answer"></textarea>
                  </div>
                </li>
              </ol>

              <div class="sendAllAnswer">
                <div class="submitBtn">
                  <a href="javascript:void(0);" @click="send">送出</a>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="otherLinks">
        <template v-if="Finish">
          <div class="questLink" v-if="Lesson.QuestionnaireUrl">
            <p>課後滿意度調查</p>
            <a href="javascript:void(0)" @click="showQuestion">
              <img src="/img/share.svg" alt="">
            </a>
            <a href="javascript:void(0)" @click="showQuestion" class="qr-trigger">
              <img src="/img/QRcode.svg" alt="">
              <div text="https://forms.gle/FwSeik9yiNGrL9ix9" class="qr-img">
                <img crossorigin="anonymous"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAABMdJREFUeF7tnWF22zAMg9s75f4nyJ2y13lv04tD6YMoJlqC/mxpmQZIEFLc9vt2u92+/LUNAt8mZBsufidiQvbiw4RsxocJMSG7IbBZPuEMuVwuy1O9Xq9/14zWb2PUBFblTPJUc2vje89oQh4ga0IaUNwhwcawbf9VIGXWiSSCSF/mWTLXtjnTdZBkZYCkicxqsgkRkTMhB2AUB7lDiJOJhiL5ftTmYh18RV1dkX+Um/q83aOTiNGKB8qAR8BQSSZArsLhPn93iGB7VTtMiN2SkGjTRHV3JGeZdaqVwoQ0pwUjInvD+K0lyx3yD4EtZgip1Bk9Hq1LpOzjJYu4JgLkiAy6NzAhAZLukOb4vfrohFQ8qVSynyHW9VXkT20MiRSoh2omJDHUTYiKQMFZ1lwK56uIFKjS9Mz4ChymNoYViTwTyFWFUIGDCQnMCimQlxKy6uZkHQKG6o4iY0HyybhKsn4vZos3F00IcFlZppXrTQgghJxmtqBnNmWqRJDc1EFOjmxUGSQ5lA11EzJn800IeHtS7XzSXeQ04medrYe6Mod6sQQMMscI8FEMycGENOiZEACGOyRAgLTbKuejkqDmpjq6TD5kFuGhHlk84qaI1hIgCRhkHRJD7kViosKkhYCGOnkgd8hBVxkhBOCoJQmBmWrLdKA6vDNnaKrKdF2WCTngNCGB+yIdRY4q3qJDyEAiHUUMAQGVkKNquRofydGMs2qvkV+UIztRovFEXzOzSAVYjTchiXdySdVuT0imAkhlE4lTXRwBlcSQ7q1Qiq7LMiHnfUX1LDUhTdWphmObDiGJk6FO7CdxXEQeCXjkXkTKiBT3Om27o5NVRKnW2IQk3o9SwXuLDnmmFBDno8asGsCq0yOy1uveZRtDkgiRIwIAiTEhwRkUkRe1+j+eEDIgCUhkx0w6TZ0JZE1SFOrebEb2kWSZkDMChEATAn4NLzPHXtohaleQashsmjIVWUEC+eBKlWh8dELIMSF9lIjrMyHBH/okx0NP75BMxavJrnJoFbJGHJoaU7YxVAEg0kfIJBVMSCZ7JBVsUsgmJHjj/b8ihFRzpnoqKphsGKMYtTOJc6ODvM0JHb8TckirqhKnVrAJaRAwIQcYBIepGULkiNyctDaRL1VqyOAnMkXUgeRG10GSRUBdBYAKkipr5FkoeKO4shlCHsKEnOkpI2RUCTM/J3IXyaYqERkzkTEKap4/8UiyZgAfXWNCHiNkQv7gUlEgSzuEHJWPuuD+56qmkhyq1yTzk7hEmmfJJ4YzlfHoGhMSbPTUTjAhBwKqDHaH+sxioyonbbvqvqSIiPtSXZa6L7pfH0kWAZIkTtYxIZv9DyoTIhKiDloVYOJqsrIwklYid8RZRTG99WXJMiFnOMn5G5HrqaFuQt6UEOJw1Bi1UjPFpVp7Kt0vkywVbKLZJiQoEzJ0TYh4uEjcDmnbzDpqV7TxqhwRZ0Vkhzxvb8BvJ1kZkk1IgWSZEHFjSFo7qlQyW8i1agzJOSM1mWu3OcvK6LEJEf/wCwHMhCRcFml5EkP2CaqzUuWiwn1Fz06KbkqyCNgkxoSMUUK2d7wMizAhY5xe9tbJOLXPjDAhm/FuQkzIZghslo47xIRshsBm6bhDNiPkF2ZvVFzzWFfnAAAAAElFTkSuQmCC"
                  width="100" height="100" style="width: 100px; height: 100px;">
              </div>
            </a>
          </div>
        </template>
      </div>
    </div>
    <div class="popUp hide" id="not_complete">
      <div class="alertWindow p_notEnough">
        <div class="p_head">
          <p>提示訊息</p>
        </div>
        <div class="p_content">
          <p>您目前還有 {{ NotCompleteCount }} 題尚未完成，是否確定要送出？</p>
        </div>
        <div class="p_foot">
          <a class="previewFirst" href="javascript:void(0)" @click="doSend">是</a><a class="previewFirst"
            href="javascript:void(0)" @click="hideConform">否</a>
        </div>
      </div>
    </div>
    <div class="popUp hide" id="sent">
      <div class="alertWindow p_notEnough">
        <div class="p_head">
          <p>提示訊息</p>
        </div>
        <div class="p_content">
          <p>測驗結束</p>
        </div>
        <div class="p_foot">
          <a href="javascript:void(0);" @click="ok">確定</a>
        </div>
      </div>
    </div>
    <div class="popUp hide" id="question">
      <div class="alertWindow form">
        <div class="p_head">
          <p>課後滿意度調查</p>
        </div>
        <div class="p_content" v-if="Lesson">
          <iframe :src="Lesson.QuestionnaireUrl" width="640" height="1462" frameborder="0" marginheight="0"
            marginwidth="0">載入中…</iframe>
        </div>
        <div class="p_foot"><a href="javascript:void(0)" @click="onQuestionClose">關閉問卷調查</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBar from "@/components/PageBar.vue";
import moment from "moment";

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Exam: null,
      Lesson: null,
      User: null,
      StartTime: null,
      LeftTime: 99999,
      Questions: null,
      NotCompleteCount: 0,
      Secret: null,
      Finish: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.setBase("articlePage exam" + this.$route.params.id);
      var [result, exam, me] = await Promise.all([
        this.$api.getLesson(this.$route.params.id),
        this.$api.getExam(this.$route.params.id),
        this.$api.getMe(),
      ]);

      this.Breadcrumbs = [{ Title: exam.Title, Url: "#" }];

      this.Exam = exam;
      this.Secret = exam.Secret;
      this.Lesson = result.Lesson;
      this.User = me;
      this.StartTime = new Date().getTime();
      this.LeftTime = exam.TimeLimit;

      console.log(this.StartTime);
      console.log(this.LeftTime);
      let key = `exam_${this.$route.params.id}`;
      let par = localStorage.getItem(key);
      if (par) {
        par = JSON.parse(par);
        this.StartTime = par.start;
        this.LeftTime = moment(par.end).diff(moment(), 'second');
      } else {
        let par = {
          start: moment(this.StartTime).format(DATE_TIME_FORMAT),
          end: moment(this.StartTime).add(exam.TimeLimit, 'second').format(DATE_TIME_FORMAT)
        };
        localStorage.setItem(key, JSON.stringify(par));
      }
      this.Questions = exam.Questions;

      this.counter();
    },
    counter() {
      if (this.LeftTime > 0) {
        setTimeout(() => {
          this.LeftTime--;
          if (this.LeftTime == 0) {
            this.doSend();
          } else {
            this.counter();
          }
        }, 1000);
      }
    },
    async send() {
      this.NotCompleteCount = 0;
      this.Questions.Multiple.forEach((q) => {
        if (q.Answer.length == 0) {
          this.NotCompleteCount++;
        }
      });
      this.Questions.Single.forEach((q) => {
        if (q.Answer == null) {
          this.NotCompleteCount++;
        }
      });
      this.Questions.Question.forEach((q) => {
        if (q.Answer == null) {
          this.NotCompleteCount++;
        }
      });
      if (this.NotCompleteCount > 0) {
        $("#not_complete").removeClass("hide");
        return;
      }
      this.doSend();
    },
    async doSend() {
      try {
        let result = await this.$api.sendExam(this.$route.params.id, {
          StartTime: moment(this.StartTime).format("YYYY-MM-DD HH:mm:ss"),
          EndTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          Multiple: this.Questions.Multiple,
          Single: this.Questions.Single,
          Question: this.Questions.Question,
          Secret: this.Secret,
        });
        console.log(result);
        $("#not_complete").removeClass("hide");
        if (result && result.Status == 4) {
          return alert('無法重複進行測驗');
        }
        this.Finish = true;
        $("#sent").removeClass("hide");
      } catch (e) {
        console.error(e);
        if (e.Status == 4) {
          $("#not_complete").removeClass("hide");
          return alert('無法重複進行測驗');
        }
      }
    },
    ok() {
      $("#sent").addClass("hide");
      $("#question").addClass("hide");
      $("#not_complete").addClass("hide");
    },
    hideConform() {
      $("#not_complete").addClass("hide");
    },
    onQuestionClose() {
      if (confirm('請確認問卷已填答完成後再關閉此視窗')) {
        this.ok();
      }
    },
    showQuestion(){      
      $("#question").removeClass("hide");
    }
  },
  computed: {
    LeftMin() {
      if (this.LeftTime == 9999) {
        return 0;
      }
      return Math.floor(this.LeftTime / 60);
    },
    LeftSec() {
      if (this.LeftTime == 9999) {
        return 0;
      }
      return this.LeftTime % 60;
    },
  },
};
</script>